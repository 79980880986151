
.head {
  text-align: center;
}

.User_container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.User_container table {
    align-items: center;
    border-collapse: collapse;
    border: 2px solid rgb(140 140 140);
    font-family: sans-serif;
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  .User_container body {
    background-color: darkgrey;
  }

  .User_container caption {
    caption-side: top;
    padding: 10px;
    font-weight: bold;
  }

 .User_container thead,
  tfoot {
    background-color: white;
  }

  .User_container th,
  td {
    border: 1px solid rgb(160 160 160);
    padding: 8px 10px;
  }

  .User_container td:last-of-type {
    text-align: center;
  }

  .User_container tbody {
    background-color: white;
  }

  .User_container tfoot th {
    text-align: right;
  }

  .User_container tfoot td {
    font-weight: bold;
  }

  .Uform form {
      align-content: center;
      width: 400px;
      margin-top: 100px;
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      position: absolute;
      left: 28.5%;
  }

  .addremove {
      display: inline-block;
      margin-bottom: 15px;
  }

  label {
      display: block;
      margin-bottom: 8px;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;

      border: 1px solid #ddd;
      border-radius: 4px;
      box-sizing: border-box;
  }
  fieldset{
      margin-bottom: 10px;
  }

  input[type=checkbox]{
      display: inline-block;
  }

  input[type="submit"] {
      background-color: #4CAF50;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }