/* Acknowledgement: I used gen AI to just make the 
5th picture/item show in the next line */

.eachShoe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    gap: 10px;
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
}

.shoe-showcase {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}

.shoe-showcase a{
    text-decoration: none;
    color: black;
}

.shoe-showcase a:hover{
    color: #06f;
}

.shoe-showcase > * {
    flex: 0 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
}

h2 {
    width: 100%;
    margin-bottom: 20px;
}