.main_div {
    margin: 0 auto;
    padding: 20px;
    height: 100vh;
}

.indivShoePage {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding: 20px;
}

.indivShoe_img img {
    max-width: 500px;
    height: auto;
    object-fit: cover;
}

.indivShoe_info {
    flex: 1;
    text-align: left;
}

.price{
    font-size: 1.2rem;
    font-weight: 150;
    color: rgb(97, 97, 97);
}

/* line-height here means the indentation */
/* https://www.w3schools.com/cssref/pr_dim_line-height.php */

.description{
    font-size: 1.2rem;
    font-weight: 150;
    color: rgb(97, 97, 97);
    line-height: 1.3;  
}

.special_offer{
    color: red;
    font-size: 1.2rem;
}

/* CSS */
.button-35 {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    color: #121212;
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-family: Inter,sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin-top: 35px;
    margin-right: 30px;
    outline: none;
    padding: 1rem 1.2rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s,-webkit-box-shadow .2s;
    white-space: nowrap;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-35:hover {
    box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
  }

  .button-35 img{
    width: 20px;
    height: auto;
    margin-left: 10px;
  }
  .button-35 .wishlist{
    width: 27px;
  }

label, select, option{
    font-size: 1.2rem;
}

select{
    margin-right: 25px;
}

.dropdown_menus {
    align-items:last baseline;
    display: flex;
    gap: 10px;
}
