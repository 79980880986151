.wishlist_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-top: 50px;
}

.wishlist_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1100px;
}

.wishlist_view {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.item_image {
  width: 150px;
  height: auto;
  margin-right: 30px;
}

.item_content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.item_name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.item_details {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.item_gender,
.item_price,
.item_color,
.item_size {
  font-size: 16px;
  margin: 0;
}

.delete_btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin-left: 20px;
}

.delete_btn img {
  width: auto;
  height: 40px;
}

.wishlist_page h3 {
  text-align: center;
  margin-bottom: 10px;
  color: black;
}

.wishlist_page h4 {
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.action_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.action_btn:hover {
  background-color: #0056b3;
}

.action_btn img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

/* Empty Wishlist Styles */
.wishlist_page p {
  text-align: center;
  color: black;
  font-size: 18px;
}


.wishlistOG_btn {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 10px;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.wishlistOG_btn:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

.wishlistOG_btn img{
    width: 20px;
    height: auto;
    margin-left: 10px;
  }