.seller-dashboard {
    max-width: 900px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.seller-dashboard h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
}

.dashboard-buttons {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.dashboard-button {
    background-color: #007bff;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 250px;
    margin: 0 auto;
}

.dashboard-button:hover {
    background-color: #0056b3;
}

.dashboard-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .seller-dashboard {
        padding: 1rem;
    }

    .dashboard-button {
        width: 200px;
    }
}
