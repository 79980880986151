.checkout-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkout-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.credit-card-form {
  margin-top: 2rem;
}

.credit-card-form label {
  font-weight: 600;
  color: #555;
  margin-bottom: 0.5rem;
}

.credit-card-form input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.credit-card-form input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.checkout-button {
  width: 100%;
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 2rem;
}

.checkout-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message, .success-message {
  color: #dc3545;
  padding: 1rem;
  background-color: #f8d7da;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
}
