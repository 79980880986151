.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2 {
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
}


main {
  flex: 1; /* This allows the main content to grow and push the footer down */
  padding: 20px;
  background-color: #f0f0f0; /* Light background for contrast */
}