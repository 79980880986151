/* https://www.w3schools.com/tags/tryit.asp?filename=tryhtml_table_border_css */

.dashboard_page {
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 50px;
  }
  

#infoTable {
    border-collapse: collapse;
    width: 80%;
    max-width: 500px;
    margin: 20px auto;
  }
  
  #infoTable td, #infoTable th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  #infoTable tr:nth-child(even){background-color: #f2f2f2;}
  
  #infoTable tr:hover {background-color: #ddd;}
  
  #infoTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #686868;
    color: white;
  }

.dashboard_btn {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 10px;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.dashboard_btn:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

.dashboard_btn img{
    width: 20px;
    height: auto;
    margin-left: 10px;
  }