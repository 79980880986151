body {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.footer {
  padding: 20px 0;
  text-align: center;
  font-size: 1.2rem;
}