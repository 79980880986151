.view_search_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.search_page {
  margin-bottom: 2rem;
  text-align: center;
}

.search_page h3 {
  margin-bottom: 1.5rem;
}

.search_form {
  max-width: 600px;
  margin: 0 auto;
}

.search_box {
  background-color: white;
  width: 600px;
  font-size: 15px;
}

.search_input {
  display: flex;
  gap: 1rem;
}

.search_input input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.search_btn {
  padding: 0.75rem 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.search_btn:hover {
  transform: scale(1.1);
}

.search_btn img {
  width: auto;
  height: 35px;
  margin-left: -150px;
}

.search_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Changed to show 3 columns */
  gap: 2rem;
  margin-top: 2rem;
}

.search_card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.search_card:hover {
  transform: translateY(-5px);
}

.search_image {
  height: 250px;
  overflow: hidden;
}

.search_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search_details {
  padding: 1.5rem;
}

.search_details h3 {
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
}

.search_details .description {
  color: #777;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.search_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.item_price, .item_gender {
  font-size: 1.1rem;
}

.item_price{
  color: #28a745;
  font-weight: bold;
}

.item_link {
  background: #f8f9fa;
  padding: 0.25rem 0.75rem;
  margin-top: 30px;
  margin-bottom: -5px;
  border-radius: 15px;
  font-size: 0.9rem;
  color: black;
}

@media (max-width: 768px) {
  .view_search_container {
      padding: 1rem; /* Adjusted padding for smaller screens */
  }
  
 .search_input {
      flex-direction: column; /* Stack input and button on smaller screens */
 }
  
 .search_grid {
      grid-template-columns: repeat(2, 1fr); /* Change to show only 2 columns on smaller screens */
 }
}

