.cart_page {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 50px auto;
  max-width: 1200px;
  padding: 0 20px;
}

.cart_items {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

.cart_item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  align-items: center;
}

.cart_item_image {
  width: 150px; 
  height: 240px; 
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; 
}

.cart_item_image img {
  width: 100%; 
  height: 100%;
  object-fit: cover; 
  border-radius: 8px;
}

.cart_item_details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cart_item_actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.quantity_control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity_button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
}

.cart_summary {
  margin-top: 30px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.empty_cart {
  text-align: center;
  margin-top: 50px;
}

.action_btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
}

.action_btn img {
  width: 20px;
  height: 20px;
}

.cart_item_image {
  width: 150px;
  margin-right: 20px;
}


.cartOG_btn {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 10px;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.cartOG_btn:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

.cartOG_btn img{
    width: 20px;
    height: auto;
    margin-left: 10px;
  }