.banner{
    width: 100%;
    height: auto;
}

h3{
    font-size: 1.7rem;
}

.first-statement{
    font-weight: 600;
    padding: 15px;
    font-size: 1.7rem;
}
.second-statement{
    font-size: 1.3rem;
    padding: 10px;
}

.button-container {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }

/* CSS */
.button-85 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6em 2em;
    width: 79px;
    height: 25px;
    font-size: 1.2rem;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-85:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  a {
    text-decoration: none;
  }