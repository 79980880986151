.item {
    width: 350px;
}

.item p {
    margin: 6px 0px;
}

.image {
    width: 100%;
    padding-top: 100%; /* This creates a 20% aspect ratio */
    position: relative;
    overflow: hidden;
}

.image img {
    position: absolute;
    top: 0;
    left: 0;
    gap: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}