.navbar{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 16px;
}

/* copied from https://github.com/aayamrajshakya/LeetcodeProfileViewer/blob/main/static/css/styles.css */
.nav-logo h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 20px;
    background: linear-gradient(to right, #DF6756, #DF6610);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0;
}

.nav-logo img{
    height: 1%;
}

.nav-link{
  text-decoration: none;
  margin-top: 30px;
  color: #000;
}

.nav-menu{
    display: flex;
    align-items: flex-start;
    list-style: none;
    gap: 40px;
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.nav-link:hover {
    border-color: #06f;
    color: #06f;
    fill: #06f;
  }

  .nav-icons {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 30px;
  }
  
  .nav-login img,
  .nav-dashboard img,
  .nav-search img,
  .nav-cart img,
  .nav-wishlist img {
    width: 25px;
    height: auto;
    display: block;
  }